<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Contact'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Contact Edit'"
      :title="'Contact Edit'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Edit</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :value="formDataToSend.name"
              :placeholder="'Name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>
          <p class="form-create__label">Email</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'email'"
              :label="'email'"
              :type="'text'"
              :placeholder="'Email'"
              :error-messages="validationErrors['email']"
              isBordered
            />
          </div>
          <p class="form-create__label">Phone</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'phone'"
              :label="'phone'"
              :type="'phone'"
              :placeholder="'Phone'"
              :error-messages="validationErrors['phone']"
              isBordered
            />
          </div>
          <p class="form-create__label">Job</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'job'"
              :label="'job'"
              :type="'job'"
              :placeholder="'Job'"
              :error-messages="validationErrors['job']"
              isBordered
            />
          </div>

          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Edit</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import usersApi from "~/api/user";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";

import { getStoredUserInfo } from "~/helpers/userAuth";

export default {
  name: "ContactEdit",
  metaInfo: {
    title: "Contact Edit",
  },
  data() {
    return {
      formDataToSend: {
        name: "",
        email: "",
        job: "",
        phone: "",
      },
      user: null,
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
  },
  mounted() {
    if (getStoredUserInfo()) {
      this.user = getStoredUserInfo();
    }
    this.getItem();
  },
  computed: {
    ...mapState("users", ["error", "loadingProcess", "validationErrors"]),
    getFirstLink() {
      return ROUTE.CONTACT_LIST.replace(":page", 1);
    },
    getId() {
      return this.$route.params.id;
    },
  },
  methods: {
    handleSubmit() {
      const data = {
        name: this.formDataToSend.name.length ? this.formDataToSend.name.trim() : "",
        email: this.formDataToSend.email.length ? this.formDataToSend.email : "",
        job: this.formDataToSend.job.length ? this.formDataToSend.job : "",
        phone: this.formDataToSend.phone.length ? this.formDataToSend.phone : "",
        developer_id: this.user.developerId,
      };
      const url = `/${this.getId}`;
      this.$store.dispatch("users/editContact", { url: url, editData: data });
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.CONTACT_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
    },
    getItem() {
      const url = `/${this.getId}`;
      try {
        usersApi.getItemContact(url).then((res) => {
          this.$store.commit("users/setError", {});
          this.$store.commit("users/setValidationErrors", {});
          const data = res.data;
          this.formDataToSend.name = data.name;
          this.formDataToSend.email = data.email;
          this.formDataToSend.job = data.job;
          this.formDataToSend.phone = data.phone;
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
